<template>
    <div class="container-fluid">
        <div class="box">
            <div class="row">
                <div class="col-12">
                    <form @submit.prevent="validForm">
                        <!-- Nom -->
                        <div class="form-group">
                            <label>{{ $t('horse.form.nom') }}<sup>*</sup></label>

                            <div class="input-group">
                                <input class="form-control" type="text" :placeholder="$t('horse.form.nom')" v-model="horse_nom" id="horse_nom" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <!-- Sexe -->
                                <div class="form-group">
                                    <label>{{ $t('horse.form.sexe') }}<sup>*</sup></label>
                                    <e-select
                                        v-model="horse_sexe"
                                        id="sexe_code"
                                        track-by="sexe_code"
                                        label="sexe_label"
                                        :placeholder="$t('horse.placeholder.sexe')"
                                        :selectedLabel="$t('select.selectedLabel')"
                                        :options="horse_sexes"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :loading="isLoadingHorseSexe"
                                        :show-labels="false"
                                    >
                                        <template slot="first" slot-scope="{ option }">{{ option.label }}</template>
                                        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                    </e-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <!-- Robe -->
                                <div class="form-group">
                                    <label>{{ $t('horse.form.robe') }}</label>
                                    <e-select
                                        v-model="horse_robe"
                                        id="robe_code"
                                        track-by="robe_code"
                                        label="robe_label"
                                        :placeholder="$t('horse.placeholder.robe')"
                                        :selectedLabel="$t('select.selectedLabel')"
                                        :options="horse_robes"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :loading="isLoadingHorseRobe"
                                        :show-labels="false"
                                    >
                                        <template slot="first" slot-scope="{ option }">{{ option.label }}</template>
                                        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                    </e-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <!-- Race -->
                                <div class="form-group">
                                    <label>{{ $t('horse.form.race') }}</label>
                                    <e-select
                                        v-model="horse_race"
                                        id="race_code"
                                        track-by="race_code"
                                        label="display_label"
                                        :placeholder="$t('horse.placeholder.race')"
                                        :selectedLabel="$t('select.selectedLabel')"
                                        :options="horse_races"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :loading="isLoadingHorseRace"
                                        :show-labels="false"
                                    >
                                        <template slot="first" slot-scope="{ option }">{{ option.label }}</template>
                                        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                    </e-select>
                                </div>
                            </div>
                        </div>

                        <!-- Date de naissance -->
                        <div class="form-group">
                            <label>{{ $t('horse.form.datenaissance') }}</label>
                            <div class="input-group">
                                <e-datepicker v-model="horse_datenaissance" />
                                <div class="input-group-append">
                                    <span class="input-group-text"><font-awesome-icon :icon="['fal', 'calendar-alt']" /></span>
                                </div>
                            </div>
                        </div>

                        <!-- Message d'erreur si besoin -->
                        <ErrorAlert v-if="message_erreur_code !== ''" :messageI18n="message_erreur_code" />

                        <!-- Bouton de validation du formulaire -->
                        <div class="text-center mt-5">
                            <button type="submit" v-on:click="checkForm" class="btn btn-primary rounded-pill">
                                {{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus-circle']"/>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus';

    import ConfigMixin from '@/mixins/Config';
    import HorseMixin from '@/mixins/Horse';
    import Tools from '@/mixins/Tools';

    export default {
        name: "HorseCreation",
        mixins: [ConfigMixin, HorseMixin, Tools],
        data () {
            return {
                horse_races: [],
                horse_robes: [],
                horse_sexes: [],

                horse_nom: null,
                horse_race: null,
                horse_robe: null,
                horse_sexe: null,
                horse_datenaissance: null,

                required_values: [
                        'horse_nom',
                        'horse_race',
                        'horse_sexe',
                        'horse_robe'
                    ],
				message_erreur_code: '',
				isLoadingHorseSexe: true,
				isLoadingHorseRobe: true,
				isLoadingHorseRace: true,
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.horse_races = await this.getRaces()
                for (const horse of this.horse_races){
                    horse.display_label = this.$t("race." + horse.race_code) +' / '+ horse.race_label
                }
                this.horse_robes = await this.getRobes()
				this.horse_sexes = await this.getSexes()
				
				this.isLoadingHorseSexe = false
				this.isLoadingHorseRobe = false
				this.isLoadingHorseRace = false

				this.horse_robe = this.horse_robes.find(robe => robe.robe_code === 'UKN')
				this.horse_race = this.horse_races.find(race => race.race_code === 'UKN')
            },

            async validForm() {
                let horse = {
                    horse_nom: this.horse_nom,
                    horse_sexe: this.horse_sexe.sexe_code,
                    horse_robe: this.horse_robe.robe_code,
                    horse_race: this.horse_race.race_code,
                    horse_datenaissance: this.horse_datenaissance
                }
				let horse_id = await this.createHorseOffline(horse)

                if(horse_id !== 0) {
                    if(this.getConfig('skip_residence_onboarding')) {
                        this.$router.push({ name: 'horseListe' })
                    }
                    else {
                        this.$router.push({ name: 'mouvementAjout', params: { horse_id: horse_id, from: this.$options.name, type: 'entree' }})
                    }
                } else {
                    this.message_erreur_code = "error.LEP"
                }
            },

            checkForm() {
                let el = ''
                let error = false

                // On vérifie chaque input obligatoire
                for(let i=0; i<this.required_values.length; i++) {
                    el = this.required_values[i]

                    // On ajoute la classe error sur les div des v-model non remplis
                    if(this[el] === null || this[el] === undefined) {
                        error = true
                    }
                }

                if(error) {
                    this.message_erreur_code = "formulaire.erreur_champs_non_remplis"
                }
            },
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
        }
    };
</script>
